/* eslint-disable no-lonely-if */
import { Link } from 'gatsby';
import gsap, { SplitText } from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from '../../constants';
import { Typography } from '../../constants/typography';
import {
  toggleForm,
  selectIsMobile,
  selectIsTablet,
  selectScrollReady,
} from '../../state/reducers/utils';
import ScrollButton from '../buttons/scroll';
import PrimaryButton from '../buttons/primary';
import IconAsterisk from '../icons/asterisk';
import { sendTagEvent } from '../../constants/google-tags';
import { utmGet } from '../../utils/utmExtractor';

const utm = utmGet();
const connectUrl = process.env.NODE_ENV === Constants.PRODUCTION ? `https://connect.spotter.la/${utm}` : `https://staging-cn.spotter.la/${utm}`;

gsap.registerPlugin(SplitText);
const ConnectPanels = ({
  creators,
  brands,
  press,
  misc,
  connect,
}) => {
  const dispatch = useDispatch();
  const panels = useRef([]);
  const [scrollColor, setScrollColor] = useState('text-white');
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const scrollReady = useSelector(selectScrollReady);
  const hero = useRef(null);
  const [current, setCurrent] = useState(0);

  // redirect to /s/?utm_source=
  // need Salesforce staging url

  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.on('scroll', (e) => {
        if (e.scroll.y > 50) {
          setScrollColor('text-charcoal');
        } else {
          setScrollColor('text-white');
        }
      });
    }
  }, [scrollReady]);

  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.on('scroll', (e) => {
        if (e.scroll.y > window.innerHeight * (isMobile ? 0.45 : isTablet ? 0.5 : 0.25)) {
          if (current !== 1) {
            setCurrent(1);
          }
        } else {
          if (current !== 0) {
            setCurrent(0);
          }
        }
      });
    }
  });

  return (
    <div id="connect" className="bg-charcoal relative overflow-hidden  tp:h-auto tl:h-auto lg:h-[300vh]" ref={hero}>
      <div id="connect-sticky" className="relative">
        <div
          className={`
            ${Constants.panelBase}
            light-header header-section
            sm:responsive-h-screen
            flex flex-col justify-center items-center
            px-6 pb-16 lg:p-16
            z-10
            text-yellow
            bg-charcoal
          `}
          ref={(el) => { panels.current.push(el); }}
        >
          <h1
            className={`
              ${Typography.h1}
              ${Typography.strokedWhite}
              max-w-[600px]
              text-center lg:text-left
              ${Constants.fadeUp}
            `}
            data-scroll
            data-scroll-class="fade-up-visible"
            data-scroll-offset="25%"
            data-scroll-repeat="true"
          >
            {misc.creatorsTitle}
          </h1>
          <h5
            className={`
              ${Typography.h5}
              text-white
              py-10
              ${Constants.fadeUp}
            `}
            data-scroll
            data-scroll-class="fade-up-visible"
            data-scroll-offset="25%"
            data-scroll-repeat="true"
          >
            {creators.subtitle}
          </h5>
          <div className="flex flex-col justify-center items-center space-y-4 sd:space-y-0 lg:space-y-4">
            <PrimaryButton
              buttonId="spotterla_connect_strikeadeal"
              className={`
                connect-button
                ${Constants.fadeUp}
              `}
              data-scroll
              data-scroll-class="fade-up-visible"
              data-scroll-offset="25%"
              data-scroll-repeat="true"
              href={connectUrl}
              target="_blank"
              onClick={() => {
                sendTagEvent('click', {
                  event_category: 'Connect',
                  event_label: 'Strike a deal',
                });
              }}
              color="white"
            >
              {creators.buttonA.label}
            </PrimaryButton>
            <Link
              id="spotterla_connect_browsefaqs"
              className={`hover:underline connect-button ${Constants.fadeUp} text-white ${Typography.smallMessage}`}
              data-scroll
              data-scroll-class="fade-up-visible"
              data-scroll-offset="25%"
              data-scroll-repeat="true"
              to={creators.buttonB.link}
              onClick={() => {
                sendTagEvent('click', {
                  event_category: 'Connect',
                  event_label: 'Browse FAQs (Creator section)',
                });
              }}
            >
              {creators.buttonB.label}
            </Link>
          </div>
          {
            (isMobile || isTablet) && (
              <div
                className={`scroll-button-wrapper ${Constants.stickyScrollButtonMobile}`}
              >
                <ScrollButton
                  location="connect"
                  arrowNo={current + 1}
                  positionClassName="relative"
                  colorClassName="text-white"
                  scrollTo="brands"
                />
              </div>
            )
          }
        </div>
        <div
          id="brands"
          className={`
            ${Constants.panelBase}
            dark-header header-section
            sm:responsive-h-screen
            flex flex-col justify-center items-center
            px-6 pb-16 lg:p-16
            z-10
            text-charcoal
            bg-tan
          `}
          ref={(el) => { panels.current.push(el); }}
        >
          <h1
            className={`
              ${Typography.h1}
              ${Typography.strokedCharcoal}
              max-w-[600px]
              text-center lg:text-left
              ${Constants.fadeUp}
            `}
            data-scroll
            data-scroll-class="fade-up-visible"
            data-scroll-offset="25%"
          >
            {misc.brandsTitle}
          </h1>
          <h5
            className={`
              ${Typography.h5}
              text-charcoal
              py-10
              ${Constants.fadeUp}
            `}
            data-scroll
            data-scroll-class="fade-up-visible"
            data-scroll-offset="25%"
          >
            {brands.subtitle}
          </h5>
          <div className="flex flex-col justify-center items-center space-y-4 sd:space-y-0 lg:space-y-4">
            <PrimaryButton
              buttonId="spotterla_connect_getintouch"
              className={`
                connect-button
                ${Constants.fadeUp}
              `}
              data-scroll
              data-scroll-class="fade-up-visible"
              data-scroll-offset="25%"
              data-scroll-repeat="true"
              onClick={() => {
                sendTagEvent('click', {
                  event_category: 'Connect',
                  event_label: 'Get in Touch',
                });
                dispatch(toggleForm(true, Constants.BRANDS));
              }}
              color="charcoal"
            >
              {brands.buttonA.label}
            </PrimaryButton>
            <Link
              id="spotterla_connect_browsefaqs"
              className={`hover:underline connect-button ${Constants.fadeUp} text-charcoal ${Typography.smallMessage}`}
              data-scroll
              data-scroll-class="fade-up-visible"
              data-scroll-offset="25%"
              data-scroll-repeat="true"
              to={brands.buttonB.link}
              onClick={() => {
                sendTagEvent('click', {
                  event_category: 'Connect',
                  event_label: 'Browse FAQs (Brands section)',
                });
              }}
            >
              {brands.buttonB.label}
            </Link>
          </div>
          <div
            className={`
              brands-note
              absolute
              sm:w-[310px]
              sm:px-10
              sm:left-0
              tp:w-60 lg:w-60
              bottom-18 tp:bottom-24 lg:bottom-24
              tp:right-4 lg:right-16
              text-left
              text-[24px]
              ${Constants.fadeUp}
            `}
            data-scroll
            data-scroll-class="fade-up-visible"
            data-scroll-offset="25%"
            data-scroll-repeat="true"
          >
            <IconAsterisk className="animate-rotate w-14 mb-1 tp:w-8 lg:w-14 lg:mb-3" />
            {connect.asteriskText}
          </div>
          {
            (isMobile || isTablet) && (
              <div
                className={`scroll-button-wrapper ${Constants.stickyScrollButtonMobile}`}
              >
                <ScrollButton
                  location="connect"
                  arrowNo={current + 1}
                  positionClassName="relative"
                  colorClassName="text-charcoal"
                  scrollTo="press"
                />
              </div>
            )
          }
        </div>
        {
          !isMobile && !isTablet && (
            <div
              className={`
                absolute
                top-0 right-0
                z-10
                w-[20%] h-screen
              `}
              data-scroll
              data-scroll-sticky
              data-scroll-target="#connect-sticky"
            >
              <ScrollButton
                location="connect"
                arrowNo={current + 1}
                className="hidden lg:block"
                colorClassName={`transition-all duration-500 ${scrollColor}`}
                scrollTo="press"
                dynamic
              />
            </div>
          )
        }
      </div>
      <div
        id="press"
        className={`
          ${Constants.panelBase}
          dark-header header-section
          sm:responsive-h-screen
          flex flex-col justify-center items-center
          px-6 pb-16 lg:p-16
          z-10
          text-charcoal
          bg-yellow
        `}
        ref={(el) => { panels.current.push(el); }}
      >
        <h1
          className={`
            ${Typography.h1}
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="25%"
          data-scroll-repeat="true"
        >
          {misc.pressTitle}
        </h1>
        <h5
          className={`
            ${Typography.h5}
            text-charcoal
            py-10
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="25%"
          data-scroll-repeat="true"
        >
          {press.subtitle}
        </h5>
        <PrimaryButton
          id="spotterla_connect_contactus"
          className={`
            connect-button
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="25%"
          data-scroll-repeat="true"
          href={`mailto:${misc.spotterPressEmail}`}
          target="_blank"
          color="charcoal"
          hoverClassName="hover:border-white hover:bg-white hover:text-charcoal"
          onClick={() => {
            sendTagEvent('click', {
              event_category: 'Connect',
              event_label: 'Contact Us',
            });
          }}
        >
          {press.buttonA.label}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ConnectPanels;
