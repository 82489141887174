/* eslint-disable no-console */
import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Layout from '../components/app-layout';
import ConnectPanels from '../components/connect/panels';

const ConnectPage = ({ data }) => {
  const { misc } = data.misc;
  const { connect } = data.connect;
  const { creators, brands, press } = connect;
  return (
    <Layout defaultHeaderColor="text-white" footerColor="bg-white">
      <Seo title={data.connect.title} />
      <div className="w-full h-full" data-scroll-section>
        <ConnectPanels
          misc={misc}
          connect={connect}
          creators={creators}
          brands={brands}
          press={press}
        />
      </div>
    </Layout>
  );
};

export default ConnectPage;

export const connectData = graphql`
  {
    misc: wpGlobalContent(databaseId: {eq: 51}) {
      misc {
        spotterPressEmail
        creatorsTitle
        brandsTitle
        pressTitle
      }
    }
    connect: wpPage(databaseId: {eq: 174}) {
      title
      connect {
        asteriskText
        creators {
          subtitle
          buttonA {
            label
            link
          }
          buttonB {
            label
            link
          }
        }
        brands {
          subtitle
          buttonA {
            label
            link
          }
          buttonB {
            label
            link
          }
        }
        press {
          subtitle
          buttonA {
            label
            link
          }
        }
      }
    }
  }
`;
